
.banner_child {
  .left_banner {
    width: 100%;
    max-height: 250px;
    overflow: hidden;
    margin-bottom: 24px;
  }
  .left_banner2 {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    margin-bottom: 24px;
  }
}
.left-panel {
  @media screen and (max-width: 768px) {
    display: none;
  }
  width: 300px;
  min-width: 300px;
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    min-width: 250px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    min-width: 220px;
  }
  img {
    width: 100%;
    height: auto;
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media screen and (min-width: "768px") and (max-width: "1366px") {
      width: 100%;
    }
  }

  .email-form {
    margin-bottom: 24px;
    .newbgform {
      background: #fff;
      border: #d7d7d7 solid thin;
      padding: 15px;

      .form-group {
        margin-bottom: 16px;
        label {
          font-size: 16px;
          color: #000000;
          margin-bottom: 10px;
          display: block;
        }
        .form-control {
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          height: 50px;
          outline: none;
        }
      }
      .form-group-btn {
        .form-btn {
          background: #fa8c28;
          padding: 12px 15px;
          font-size: 16px;
          color: #ffffff;
          font-weight: 500;
          border-radius: 5px;
          width: 100%;
        }
      }
    }
  }
}
